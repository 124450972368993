import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';

import { H2, H3, H4, H5, P } from '../ui/Typography';
import Button from '../ui/Button';
import RadioGroup from '../ui/RadioGroup';
import { useStartQuestionnaire } from './hooks';
import { Container, Row, Alert, Navigation, Gutter, Card, Checkbox } from './styles';
import { questionnaireStart } from 'data/content';
import { logAnalyticsEvent } from 'services/analytics';

function QuestionnaireStart() {
    const { startQuestionnaire } = useStartQuestionnaire();
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');
    const [isRadioChecked, setIsRadioChecked] = useState(false);

    const handleRadioChange = (event: any) => {
        setIsRadioChecked(event.target.checked);

        logAnalyticsEvent({
            action: 'Landing page',
            label: 'I accept',
        });
    };

    return (
        <Container>
            <H3>{questionnaireStart.subtitle}</H3>

            <P>{questionnaireStart.contentOne}</P>
            <P>{questionnaireStart.contentTwo}</P>
            <P>{questionnaireStart.contentThree}</P>

            <H3 className="mb-4">{questionnaireStart.riskRatings.title}</H3>
            <Card>
                <RadioGroup
                    title={questionnaireStart.riskRatings.listTitle}
                    name="risk-level"
                    value={selectedRiskLevel}
                    onChange={setSelectedRiskLevel}
                >
                    <div className="flex">
                        {questionnaireStart.riskRatings.radioGroup.map(riskLevel => (
                            /* Use the `active` state to conditionally style the active option. */
                            /* Use the `checked` state to conditionally style the checked option. */
                            <HeadlessRadioGroup.Option key={riskLevel.level} value={riskLevel.level}>
                                {({ checked }) => (
                                    <li
                                        onClick={() =>
                                            logAnalyticsEvent({
                                                action: riskLevel.eventAction,
                                                label: riskLevel.eventLabel,
                                            })
                                        }
                                        className={`${checked ? 'bg-blue' : ''}`}
                                    >
                                        <P className={`${checked ? 'text-white' : 'text-blue'}`}>
                                            {riskLevel.levelNumber}
                                        </P>
                                        <div className="hide">{riskLevel.level}</div>
                                    </li>
                                )}
                            </HeadlessRadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>

                {selectedRiskLevel && (
                    <div className="px-4">
                        {questionnaireStart.riskRatings.riskGroup.map(
                            riskLevel =>
                                selectedRiskLevel === riskLevel.level && (
                                    <div key={riskLevel.level}>
                                        <H5 className="mt-0">{riskLevel.level}</H5>
                                        <P>{riskLevel.content}</P>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </Card>

            <Row>
                <Col>
                    <H3>{questionnaireStart.riskRatings.heading}</H3>
                    <P>{questionnaireStart.riskRatings.content}</P>
                    <Gutter />
                </Col>
            </Row>

            <ul>
                {questionnaireStart.riskRatings.list.map((listItem, index) => (
                    <li key={index}>{listItem.content}</li>
                ))}
            </ul>
            <P>
                <strong>
                    {questionnaireStart.riskRatings.contentTwo}
                    <a target="_blank" rel="noreferrer" href={questionnaireStart.riskRatings.linkUrl}>
                        {questionnaireStart.riskRatings.linkText}
                    </a>
                    {questionnaireStart.riskRatings.contentThree}
                </strong>
            </P>

            <H2>{questionnaireStart.attitudeRisk.title}</H2>
            <P>{questionnaireStart.attitudeRisk.content}</P>

            <H4>{questionnaireStart.attitudeRisk.heading4}</H4>

            <Alert title={questionnaireStart.attitudeRisk.alertHeading}>
                <P>{questionnaireStart.attitudeRisk.alertContent}</P>
            </Alert>

            <div className="mb-5">
                <P>Standard Life's Risk Profiler</P>
                <P>
                    The Risk Profiler provided by Standard Life is an interactive tool created to help you gain insights
                    into your risk tolerance, an important factor to consider before investing.
                </P>

                <P>
                    This Risk Profiler is for illustrative purposes only and is based on the inputs you provide and
                    assumptions incorporated into the tool. While Standard Life aims for accuracy in this Risk Profiler,
                    we cannot assure its complete error-free operation or suitability for every user's unique
                    circumstances. Standard Life are not responsible for any investment loss or damage incurred due to
                    reliance on the information obtained from this Risk Profiler. The Risk Profiler should not be
                    interpreted as advice from Standard Life. It is important that you examine your personal financial
                    situation and discuss your investment goals with a financial adviser before making an investment
                    decision.
                </P>

                <P>
                    A range of significant aspects need to be accounted for when assessing your risk tolerance, and our
                    Risk Profiler may only capture some of these aspects. Securing professional guidance is essential
                    and by selecting "accept", you're confirming that:
                </P>
                <ul>
                    <li>You haven't sought any advice from Standard Life; and</li>

                    <li>
                        You acknowledge that the Risk Profiler by Standard Life is a general guide for illustrative
                        purposes only and not a replacement for professional advice from your financial adviser.
                    </li>
                </ul>
            </div>
            <Checkbox id="disclaimer__input" label="I accept" checked={isRadioChecked} onChange={handleRadioChange} />

            <Navigation className="mb-5 text-center">
                <Button disabled={!isRadioChecked} type="button" onClick={startQuestionnaire}>
                    {questionnaireStart.attitudeRisk.buttonText}
                </Button>
            </Navigation>
        </Container>
    );
}

export default QuestionnaireStart;
