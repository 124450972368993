import { Col } from 'react-bootstrap';
import { Container, Row, ResultsWrapper, RiskRatingCard } from './styles';
import { useRiskRating } from './hooks';
import { H2, P } from '../ui/Typography';
import { resultsContent } from 'data/content';
import Button from '../ui/Button';
import LoadingState from './LoadingState';

export interface Risk {
    level: string;
    description: string;
}

function Results() {
    const { imageSrc, rating, riskRating, description, retakeQuestionnaire } = useRiskRating();

    return (
        <Container className="my-3">
            {!rating && <LoadingState />}

            {rating && (
                <ResultsWrapper>
                    <RiskRatingCard>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <H2>
                                    {resultsContent.title} {riskRating} - {rating}
                                </H2>
                                <P>{description}</P>

                                <Button secondary onClick={retakeQuestionnaire}>
                                    {resultsContent.buttonText}
                                </Button>
                            </Col>

                            <Col xs={{ order: 'first', span: 12 }} lg={{ order: 'last', span: 6 }}>
                                <img className="img-fluid" src={imageSrc} alt="risk rating info" />
                            </Col>
                        </Row>
                    </RiskRatingCard>
                </ResultsWrapper>
            )}
        </Container>
    );
}

export default Results;
