import styled from 'styled-components';
import uiCheckIcon from './CheckIcon';

export const Wrapper = styled.div`
    margin-bottom: 8px;
`;

export const CheckIcon = styled(uiCheckIcon)`
    position: absolute;
    height: 18px;
    width: 18px;
`;

export const Input = styled.input`
    position: absolute;
    cursor: pointer;
    height: 18px;
    width: 18px;
    appearance: none;
    box-sizing: border-box;

    &::before {
        display: block;
        content: '';
        width: 18px;
        height: 18px;
        border: 2px solid ${({ theme }) => theme.colors.fieldBorder};
        border-radius: 3px;
    }

    ~ svg {
        display: inline-block;
        opacity: 0;
        position: relative;
        top: -4px;
        width: 18px;
        height: 18px;
        border-radius: 3px;
        box-sizing: border-box;
        pointer-events: none;
    }

    &:checked {
        ~ svg {
            opacity: 1;
        }

        &::before {
            opacity: 0;
        }
    }
`;

export const Label = styled.label`
    position: relative;
    padding-left: 10px;
    top: -2px;
    max-width: calc(100% - 20px);
    color: ${({ theme }) => theme.colors.primaryText};
    user-select: none;
`;
