import styled from 'styled-components';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { breakpointUp } from '../../../helpers/breakpoints';

export const StyledRadioGroup = styled(HeadlessRadioGroup)`
    border-radius: 8px;
    padding: 16px 16px 0;

    ${breakpointUp.lg`
            padding: 16px;
    `}

    .flex {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        ${breakpointUp.sm`
            justify-content: unset;
            gap:16px;
        `}
    }

    .hide {
        display: none;
        ${breakpointUp.md`
            display: block;
        `}
    }

    li {
        cursor: pointer;
        border-radius: 4px;
        display: block;
        border: 2px solid ${({ theme }) => theme.colors.borderGrey};
        padding: 8px 16px;
        width: 100%;
        margin: 0 auto 10px;
        height: auto;

        ${breakpointUp.md`
           width: 117px;
           height: 96px;
        `}

        ${breakpointUp.lg`
           width: 125px;
        `}
    }

    .bg-blue {
        border: 2px solid ${({ theme }) => theme.colors.primaryAction};
        background-color: ${({ theme }) => theme.colors.primaryAction};
        color: ${({ theme }) => theme.colors.white};
    }

    .text-white {
        margin: 0;
        color: ${({ theme }) => theme.colors.white};
    }

    .text-blue {
        margin: 0;
        color: ${({ theme }) => theme.colors.primaryText};
    }
`;
