import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'services/analytics';
import graphQLMutation from '../../services/graphql/mutation';
import { RESET_FORM, SET_RISK_RATING, useAppContext } from '../../state';

import riskRatings from './riskRatings';
import RiskRating1 from '../../assets/img/risk-rating-1.svg';
import RiskRating2 from '../../assets/img/risk-rating-2.svg';
import RiskRating3 from '../../assets/img/risk-rating-3.svg';
import RiskRating4 from '../../assets/img/risk-rating-4.svg';
import RiskRating5 from '../../assets/img/risk-rating-5.svg';

interface RiskRating {
    hasQuestions?: boolean;
    rating?: string;
    riskRating?: string;
    description?: string;
    retakeQuestionnaire: Function;
    imageSrc?: string;
}

export const useRiskRating = (): RiskRating => {
    const { state, dispatch } = useAppContext();
    const [responsesSubmitted, setResponsesSubmitted] = useState(false);
    let rating, description, riskRating, imageSrc;

    const navigate = useNavigate();

    useEffect(() => {
        if (!state.questions.length) {
            navigate('/');
        }
        async function submitResponses() {
            try {
                const { rating: riskRating } = await graphQLMutation('calculateRisk', { responses: state.answers });
                logAnalyticsEvent({ action: `Results page`, label: `Your risk level is ${riskRating}` });
                dispatch({ type: SET_RISK_RATING, riskRating });
            } catch {
                navigate('/error');
            }
        }
        if (
            !responsesSubmitted &&
            state.answers.length > 0 &&
            state.answers.length === state.questions.length &&
            !state.riskRating
        ) {
            logAnalyticsEvent({ action: `Results page`, label: `Display` });
            setResponsesSubmitted(true);
            submitResponses();
        }
    }, [state, dispatch, responsesSubmitted, navigate]);

    const retakeQuestionnaire = useCallback(() => {
        logAnalyticsEvent({ action: `Results page`, label: `Retake the test button` });
        dispatch({ type: RESET_FORM });
        navigate('/questionnaire');
    }, [dispatch, navigate]);

    if (state.riskRating) {
        const results = riskRatings[state.riskRating.toString()];
        rating = results.rating;
        description = results.description;
        riskRating = state.riskRating;

        switch (riskRating) {
            case 1:
                imageSrc = RiskRating1;
                break;
            case 2:
                imageSrc = RiskRating2;
                break;
            case 3:
                imageSrc = RiskRating3;
                break;
            case 4:
                imageSrc = RiskRating4;
                break;
            case 5:
                imageSrc = RiskRating5;
                break;
            default:
                imageSrc = '';
        }
    }

    const hasQuestions = !!state.questions;

    return {
        imageSrc,
        hasQuestions,
        riskRating,
        rating,
        description,
        retakeQuestionnaire,
    };
};
