import { Routes, Route } from 'react-router-dom';
import Questionnaire from '../Questionnaire';
import Results from '../Results';
import QuestionnaireStart from '../QuestionnaireStart';
import Error from '../Error';

import { Container } from './styles';

function App() {
    return (
        <Container>
            <Routes>
                <Route path="/" element={<QuestionnaireStart />} />

                <Route path="questionnaire" element={<Questionnaire />} />

                <Route path="/results" element={<Results />} />

                <Route path="/error" element={<Error />} />
            </Routes>
        </Container>
    );
}

export default App;
