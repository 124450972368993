import { Answers, Answer, Fieldset, Legend, Input, Label, Navigation, Button } from './styles';
import { useQuestionForm } from './hooks';
import LoadingState from './LoadingState';
import { logAnalyticsEvent } from 'services/analytics';

function QuestionForm() {
    const { handleSubmit, register, formState, question, buttonLabel, onSubmit } = useQuestionForm();

    if (!question) return <LoadingState />;

    const { id: questionId, text, answers } = question;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset id={`question${questionId}Answer`}>
                <Legend>{text}</Legend>

                <Answers>
                    {answers.map(({ id, text }: any) => (
                        <Answer key={id}>
                            <Input
                                type="radio"
                                id={`question${id}`}
                                {...register(`answer`, { required: true })}
                                value={id}
                            />

                            <Label htmlFor={`question${id}`}>{text}</Label>
                        </Answer>
                    ))}
                </Answers>
            </Fieldset>

            <Navigation>
                <Button
                    type="submit"
                    onClick={() =>
                        buttonLabel === 'Next question'
                            ? logAnalyticsEvent({ action: `Question ${questionId}`, label: `Next question button` })
                            : logAnalyticsEvent({ action: `Question ${questionId}`, label: `Submit button` })
                    }
                    disabled={!formState.isValid}
                >
                    {buttonLabel}
                </Button>
            </Navigation>
        </form>
    );
}
export default QuestionForm;
