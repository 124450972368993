import { ChangeEventHandler } from 'react';
import { Wrapper, CheckIcon, Input, Label } from './styles';

interface CheckboxProps {
    id?: string;
    label?: string;
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox = ({ id, label, checked, onChange, ...otherProps }: CheckboxProps) => {
    return (
        <Wrapper {...otherProps}>
            <Input id={id} type="checkbox" checked={checked} onChange={onChange} />
            <CheckIcon />
            <Label htmlFor={id}>{label}</Label>
        </Wrapper>
    );
};

export default Checkbox;
