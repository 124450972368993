export const questionnaireStart = {
    subtitle: 'Check your attitude to risk',

    contentOne: `Assess how much risk you might be comfortable taking with your investments with our risk profiler. By making the decision to invest, you're giving your money more chance to potentially grow than if you're just saving it. How much risk you take can affect your potential growth so it's important to understand how much you're willing and able to take.`,

    contentTwo: `Our risk profiler helps you understand your attitude towards risk. It asks several key investment-related questions designed to assess your experience with different types of investments and your comfort level with taking risks.`,

    contentThree: `Each response is assigned a score, which is then combined to provide you with an overall risk rating - there are five levels of risk ratings.`,

    riskRatings: {
        title: 'What are the risk ratings?',
        listTitle: 'Click each level to learn more',
        radioGroup: [
            {
                levelNumber: '1',
                level: 'Low',
                eventLabel: 'Low',
                eventAction: 'Landing page',
            },
            {
                levelNumber: '2',
                level: 'Low-Medium',
                eventLabel: 'Low-Medium',
                eventAction: 'Landing page',
            },
            {
                levelNumber: '3',
                level: 'Medium',
                eventLabel: 'Medium',
                eventAction: 'Landing page',
            },
            {
                levelNumber: '4',
                level: 'Medium-High',
                eventLabel: 'Medium-High',
                eventAction: 'Landing page',
            },
            {
                levelNumber: '5',
                level: 'High',
                eventLabel: 'High',
                eventAction: 'Landing page',
            },
        ],
        riskGroup: [
            {
                level: 'Low',
                content:
                    'The most cautious approach to investing for a short time (up to a year), usually before taking your money.',
            },
            {
                level: 'Low-Medium',
                content: 'A very cautious approach to investing for the short term (up to two years).',
            },
            {
                level: 'Medium',
                content: 'A cautious approach to investing for the medium term (three to five years).',
            },
            {
                level: 'Medium-High',
                content: 'A balanced approach to investing for the medium term (three to five years).',
            },
            {
                level: 'High',
                content: 'A more adventurous approach to investing towards the long term (five to ten years).',
            },
        ],
        heading: 'How to assess your attitude to risk',
        content: 'This risk profiler can help you understand your attitude to risk but there are some limitations:',
        list: [
            {
                content: 'The profiler is designed to help you decide for yourself and shouldn’t be taken as advice',
            },
            {
                content: `The profiler doesn't consider your age, your financial goals or how much money you'd be comfortable losing i.e. your capacity for loss`,
            },
            {
                content:
                    'You should also consider how long you plan to invest for and when you think you might need access to your money',
            },
        ],
        contentTwo: `If you're not sure how to invest, it may be worth speaking to your financial adviser. If you don't have an adviser, you can find a `,
        linkText: 'list of regulated advisers here.',
        linkUrl: 'https://brokersireland.ie/ ',
        contentThree: ' You may be charged for this advice.',
    },
    attitudeRisk: {
        title: 'Take our risk profiler',
        content:
            'The risk profiler contains 13 multiple choice questions which you need to answer to discover your category.',
        heading4: 'It should take less than 5 minutes.',
        alertHeading: `We don't store your data`,
        alertContent: `You don't need to tell us anything about yourself, just how you would react to or feel in certain
        scenarios. We won't store any of this information in a way that can be linked back to you.`,
        buttonText: `I'm ready to start`,
    },
};

export const resultsContent = {
    title: 'Your risk level is',
    buttonText: 'Retake the test',
    heading: 'Next Steps',
};
